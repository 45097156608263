import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'
// import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const axios = require('axios').default

const IS_SECURE = true

const hexToUtf8 = (hex) =>
  decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'))

function encode420(str) {
  const arr = str.match(/.{1}/g)
  const nw = arr.map((ltr) => {
    if (ltr === '!') return '0'
    if (ltr === '4') return '1'
    if (ltr === '2') return '2'
    if (ltr === '0') return '3'
    if (ltr === 'i') return '4'
    if (ltr === 'a') return '5'
    if (ltr === 'm') return '6'
    if (ltr === 's') return '7'
    if (ltr === 'h') return '8'
    if (ltr === 'p') return '9'
    if (ltr === 'o') return 'a'
    if (ltr === 'n') return 'b'
    if (ltr === 'g') return 'c'
    if (ltr === 'l') return 'd'
    if (ltr === 'e') return 'e'
    if (ltr === 'd') return 'f'
    return null
  })
  const output = nw.join('')
  console.log('output', output)
  return output
}

const getIntrospection = async (introSpectionPath) => {
  let introspectionObject = null

  await axios(introSpectionPath)
    .then((response) => {
      if (response?.data) {
        introspectionObject = IS_SECURE
          ? JSON.parse(hexToUtf8(encode420(response.data)))
          : response.data
      }
    })
    .catch(function (error) {
      if (error.response) {
        console.error('error.response', error.response)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        console.error('error.request', error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('error.message', error.message)
      }

      introspectionObject = error
    })

  return introspectionObject
}

const createAppWithApi = async (thisNode, introSpectionPath) => {
  const introspectionObject = await getIntrospection(introSpectionPath)

  if (thisNode) {
    const root = ReactDOM.createRoot(thisNode)

    if (introspectionObject && introspectionObject.hasOwnProperty('requests')) {
      root.render(<App introspection={introspectionObject} />)
    } else {
      const ErrElement = (
        <section style={{ margin: '1.6em' }}>
          <h1>api not available</h1>
          <div>
            <label>
              <b>
                <a
                  style={{ color: 'inherit' }}
                  href='mailto:alivedd@icloud.com'>
                  write to support
                </a>
              </b>
              <span>&nbsp;</span>
              <FontAwesomeIcon icon={faReply} />
            </label>
          </div>
        </section>
      )
      root.render(ErrElement)
    }
  }
}

const renderNode = document.getElementById('root')

createAppWithApi(renderNode, '/api/introspection')

false && reportWebVitals(console.log)
