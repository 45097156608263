import React from 'react'

const Button = (props) => {
  const { children, onClick, className } = props
  const rootClassName = className ? `button ${className}` : 'button'

  return (
    <button
      type='button'
      className={rootClassName}
      onClick={(ev) => onClick(ev)}>
      {children}
    </button>
  )
}

export default Button
