// import logo from "./logo.svg";
import React, { useState } from 'react'
import axios from 'axios'
import './App.css'

import DotLoader from './components/DotLoader'
import Button from './components/Button'

// import KeyDownFunctional from './KeyDownFunctional'
// const isTouch = 'ontouchstart' in document.documentElement

function App(props) {
  // replace to root redux store
  const requests = props.introspection.requests

  const [isLoading, set_isLoading] = useState(false)

  const [datetimeData, set_datetimeData] = useState()
  const [isDatetimeButtonHidden, set_isDatetimeButtonHidden] = useState(false)

  const [usersData, set_usersData] = useState()
  const [isUsersButtonHidden, set_isUsersButtonHidden] = useState(false)

  const handleClickDateTime = (ev) => {
    getClickDateTimeRequest()
  }

  const getWikiList = () => {
    // https://en.wikipedia.org/w/api.php?action=query&origin=*&format=json&generator=search&gsrnamespace=0&gsrlimit=5&gsrsearch=%27New_England_Patriots%27
  }

  const handleClickGetUsers = (ev) => {
    console.log('handleClickGetUsers')
    getUsersRequest()
  }

  const getJokeRequest = async () => {
    set_isLoading(true)

    const url = 'https://ru.wikipedia.org/api/rest_v1/page/random/summary'

    const request = axios.get(url)

    const response = request.then(async (response) => {
      const output = response
        ? {
            extract: response.extract,
            originalimage: response.originalimage.source,
            originalimage: response.originalimage.width,
            originalimage: response.originalimage.height,
            content_urls: response.content_urls.desktop.page,
          }
        : null
      console.log('output', output)
      return output
    })

    set_isLoading(false)

    return response
  }

  const getUsersRequest = async () => {
    set_isLoading(true)
    set_isUsersButtonHidden(true)

    const urls = [
      'https://random-data-api.com/api/v2/users?size=3',
      'https://official-joke-api.appspot.com/random_joke',
      'https://official-joke-api.appspot.com/random_joke',
      'https://official-joke-api.appspot.com/random_joke',
    ]

    const requests = urls.map((url) => axios.get(url))

    requests.then(async (response) => {
      await set_isLoading(false)

      if (response.data) {
        let newUsersWithJokes = await response.data.map(async (item, index) => {
          const resJoke = await getJokeRequest()
          const output = {
            ...item,
            user_joke_setup: resJoke?.data?.setup,
            user_joke_punchline: resJoke?.data?.punchline,
          }
          return output
        })

        if (newUsersWithJokes.length > 0) {
          newUsersWithJokes = newUsersWithJokes.map((elem) => {
            console.log('elem', elem.result)
            return elem.result
          })

          console.log('newUsersWithJokes', newUsersWithJokes)
          await set_usersData(newUsersWithJokes)
        }
      }
    })
  }

  const getClickDateTimeRequest = () => {
    set_isLoading(true)
    set_isDatetimeButtonHidden(true)

    axios(requests.datetime).then((response) => {
      const MAGIC_TIMEOUT = 2222
      setTimeout(() => {
        set_isLoading(false)

        if (response.data.saved) {
          const resMs = parseInt(response.data.saved, 10)

          const isoDatetime = new Date(
            resMs - new Date(resMs).getTimezoneOffset() * 60 * 1000,
          ).toISOString()

          const date = isoDatetime.split('T')[0].split('-').reverse().join('-')
          const time = isoDatetime.split('T')[1].split('.')[0]
          const output = `${date}, ${time}`

          set_datetimeData(output)
        } else {
          console.error('err', response)
        }
      }, MAGIC_TIMEOUT)
    })
  }

  return (
    <>
      {isLoading && (
        <div className='page-loader-place'>
          <DotLoader />
        </div>
      )}

      <main className='page'>
        {/* datetime */}
        <section className='section' hidden={false}>
          <h1 className='title title-center'>Last click Date</h1>

          <div className='panel'>
            {!isDatetimeButtonHidden && (
              <Button onClick={handleClickDateTime}>Get</Button>
            )}

            {datetimeData && (
              <span className='text appear'>{datetimeData}</span>
            )}
          </div>
        </section>

        {/* users */}
        <section className='section'>
          <h1 className='title title-center'>Users</h1>

          {!isUsersButtonHidden && (
            <div className='panel'>
              <Button onClick={handleClickGetUsers}>Get</Button>
            </div>
          )}

          {usersData && (
            <ul className='text appear list-in-row'>
              {usersData.map((user, index) => {
                return (
                  <li className='list-item' key={index}>
                    <img
                      className='list-item-img-avatar transparent-text'
                      alt={user.id}
                      src={user.avatar}
                      width='100px'
                      height='100px'
                    />

                    {user.username && (
                      <b class='text-small'>@{user.username}</b>
                    )}

                    {user.subscription?.plan && (
                      <p>status: {user.subscription.plan}</p>
                    )}

                    <div>
                      {user.user_joke_setup && (
                        <>
                          <span>setup: </span>
                          <span>{user.user_joke_setup}</span>
                        </>
                      )}
                      {user.user_joke_punchline && (
                        <>
                          <span>punchline: </span>
                          <span>{user.user_joke_punchline}</span>
                        </>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </section>

        {/* {!isTouch && <KeyDownFunctional />} */}

        {/* {console.info('render')} */}
      </main>
    </>
  )
}

export default App
