import React from 'react'

const DotLoader = () => {
  return (
    <div className='dot-loader-wrap'>
      <div className='dot-loader dot-loader--1'></div>
      <div className='dot-loader dot-loader--2'></div>
      <div className='dot-loader dot-loader--3'></div>
    </div>
  )
}

export default DotLoader
